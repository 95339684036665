import PropTypes from 'prop-types';
import Outcomes from '../outcomes';

const SingleMarket2Column = ({ id, ...props }) => (
  <div className={`market-${id} market-view-body-wrapper outcome-2cols`}>
    <Outcomes
      id={id}
      {...props}
      outcomeClassName="outcome-button-2cols bvs-button-sport outcome-button-1x2"
    />
  </div>
);

SingleMarket2Column.propTypes = {
  id: PropTypes.number.isRequired,
};

export default SingleMarket2Column;
