import { createSelector } from 'reselect';
import { property } from 'underscore';

import { makeGetOutcomesByIds } from 'sportsbook-outcomes-selector';
import { sortByPrice } from './sorters';

const isToday = (date) => {
  const now = new Date();
  return date.getDate() === now.getDate()
    && date.getMonth() === now.getMonth()
    && date.getFullYear() === now.getFullYear();
};

const getComponents = (state) => state.featuredBets.componentsById;
const getComponentId = (_, componentId) => componentId;

export const makeGetComponentById = () => createSelector(
  [getComponents, getComponentId],
  (component, componentId) => component[componentId],
);

const getEvents = (state, { componentId, filterTodayEvents }) => {
  const getComponentById = makeGetComponentById();
  const { events } = getComponentById(state, componentId);

  return filterTodayEvents
    ? events.filter(({ startTime }) => isToday(new Date(startTime)))
    : events;
};

const getEventIds = (_, props) => props.eventIds;

export const makeGetEventsByIds = () => createSelector(
  [getEvents, getEventIds],
  (events, eventIds) => events.filter((event) => eventIds.includes(event.id)),
);

const getNumberOfOutcomes = (_, props) => props.numberOfOutcomes;

export const makeGetOutcomesByIdsSortByPrice = () => {
  const getOutcomesByIds = makeGetOutcomesByIds();

  return createSelector(
    [getOutcomesByIds, getNumberOfOutcomes],
    (outcomes, numberOfOutcomes) => (
      outcomes
        .filter((o) => !o.h)
        .sort(sortByPrice)
        .slice(0, numberOfOutcomes)
        .map(property('id'))
    ),
  );
};
