import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

const emptyResponse = () => ({
  sport: {},
  events: [],
  markets: [],
  outcomes: [],
  marketLayouts: {},
});

export default (componentId) => (
  getJSON(`/sportsbook_components/home_components/components/${componentId}`, {
    c: locale(),
  }).catch(emptyResponse)
);
