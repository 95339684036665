import PropTypes from 'prop-types';
import MultiMarketColumn from '../components/markets/multi_market_column';
import SingleMarket1Column from '../components/markets/single_market_1_column';
import SingleMarket2Column from '../components/markets/single_market_2_column';
import SingleMarket3Column from '../components/markets/single_market_3_column';

const DefaultMarket = SingleMarket1Column;

const Market = (market) => {
  const { layout } = market;
  switch (layout) {
    case '1-column':
      return <SingleMarket1Column {...market} />;
    case '2-column':
      return <SingleMarket2Column {...market} />;
    case '3-column':
      return <SingleMarket3Column {...market} />;
    case 'multi-market':
      return <MultiMarketColumn {...market} />;
    default:
      return <DefaultMarket {...market} />;
  }
};

Market.propTypes = {
  layout: PropTypes.oneOf(['1-column', '2-column', '3-column', 'multi-market']).isRequired,
};

export default Market;
