import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { marketLayoutType } from '../helpers/types';
import { makeGetEventsByIds } from '../selectors';
import Sport from './sport';

const SportContainer = ({
  events,
  id,
  componentId,
  description,
  eventPathId,
  href,
  layout,
  numberOfEventsPerCard,
}) => (
  !!events.length
    && (
    <Sport
      id={id}
      componentId={componentId}
      description={description}
      eventPathId={eventPathId}
      sportHref={href}
      events={events}
      layout={layout}
      numberOfEventsPerCard={numberOfEventsPerCard}
    />
    )
);

SportContainer.propTypes = {
  id: PropTypes.number.isRequired,
  componentId: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  eventPathId: PropTypes.number.isRequired,
  events: PropTypes.arrayOf(Object).isRequired,
  href: PropTypes.string,
  layout: marketLayoutType.isRequired,
  numberOfEventsPerCard: PropTypes.number.isRequired,
};

SportContainer.defaultProps = {
  href: null,
};

const makeMapStateToProps = () => {
  const getEventsByIds = makeGetEventsByIds();

  return (state, props) => ({
    events: getEventsByIds(state, props),
  });
};

export default connect(makeMapStateToProps)(SportContainer);
