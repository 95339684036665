import { format } from 'bv-helpers/datetime';
import SPORT_IDS from '../helpers/sport_ids';

// Extract to service if it gets more complicated
const fullDescription = (event) => {
  // Horse Racing and Greyhounds will have as event full description the meeting
  // description and the event description (which is the non localized start time)
  if ([
    SPORT_IDS.HORSE_RACING,
    SPORT_IDS.GREYHOUNDS,
  ].includes(event.sportId)) {
    return `${event.meetingDescription} - ${event.description}`;
  }

  // All other sports will have as event full description the event description
  // and the browser localized event start time
  return event.description;
};

export default (event) => ({
  ...event,
  fullDescription: fullDescription(event),
  startTime: format(event.startTime),
});
