import PropTypes from 'prop-types';
import Outcomes from '../outcomes';

const MultiMarket = ({
  id, des, ...props
}) => (
  <div className="coupon-multi-market__market">
    <p>{des}</p>
    <div className={`market-${id} market-view-body-wrapper`}>
      <Outcomes id={id} des={des} {...props} outcomeClassName="bvs-button-sport outcome-button" />
    </div>
  </div>
);

MultiMarket.propTypes = {
  id: PropTypes.number.isRequired,
  des: PropTypes.string.isRequired,
};

export default MultiMarket;
