import PropTypes from 'prop-types';
import { preEventPath } from 'bv-helpers/routes';
import classnames from 'classnames';
import { chunk } from 'underscore';
import { t } from 'bv-i18n';
import { Button, Carousel, Icon } from 'bv-components';
import { marketLayoutType } from '../helpers/types';
import presentEvent from '../presenters/event';
import EventContainer from './event_container';

const Sport = ({
  componentId, eventPathId, sportHref, description, events, layout, numberOfEventsPerCard,
}) => {
  const getEventHref = (opts) => (
    preEventPath({ ...opts, sportEventPathId: eventPathId })
  );

  const showCarousel = events.length > numberOfEventsPerCard;

  const renderedEvents = chunk(events, numberOfEventsPerCard).map((eventsGroup) => (
    <div className="bvs-card market-view-default">
      {eventsGroup.map((event) => (
        <EventContainer
          {...presentEvent(event)}
          componentId={componentId}
          getEventHref={getEventHref}
          key={event.id}
          layout={layout}
        />
      ))}

      {sportHref && (
        <p className="market-view__show-more">
          <Button to={sportHref} primary className="market-view__show-more-button">{t('javascript.see_more')}</Button>
        </p>
      )}
    </div>
  ));

  return (
    <div
      className={classnames(
        'featured-sport-wrapper',
        { 'has-more-sports': sportHref },
      )}
    >
      <h2 className="featured-sport-title bvs-ellipsis">
        <Icon id={`sport-${eventPathId}`} big type="feature-comp-title" />
        {description}
      </h2>
      {showCarousel ? (
        <Carousel scrollStep={284} largeArrows>
          {renderedEvents}
        </Carousel>
      ) : renderedEvents}
    </div>
  );
};

Sport.propTypes = {
  id: PropTypes.number.isRequired,
  componentId: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  eventPathId: PropTypes.number.isRequired,
  sportHref: PropTypes.string,
  events: PropTypes.arrayOf(Object).isRequired,
  layout: marketLayoutType.isRequired,
  numberOfEventsPerCard: PropTypes.number.isRequired,
};

Sport.defaultProps = {
  sportHref: null,
};

export default Sport;
