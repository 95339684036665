import PropTypes from 'prop-types';

import { formatPrice } from 'sportsbook-helpers';
import { EnhancedPrice } from 'sportsbook-components';
import { FormattedPrice } from 'SportsbookCommon';
import { usePriceFormatId } from 'bv-hooks';

const Outcome = ({
  title, pr, h, fractionalEnhancedOdds,
}) => {
  const priceFormatId = usePriceFormatId();
  return (
    <div className="outcome-n-wrapper">
      <span className="outcome-n-description">{title}</span>
      <span className="outcome-n-price">
        {
          fractionalEnhancedOdds
            ? (
              <EnhancedPrice
                enhancedPrice={fractionalEnhancedOdds}
                oldPrice={formatPrice(pr, priceFormatId)}
              />
            )
            : (h && '-') || <FormattedPrice price={pr} />
        }
      </span>
    </div>
  );
};

Outcome.propTypes = {
  title: PropTypes.string,
  pr: PropTypes.string.isRequired,
  h: PropTypes.bool.isRequired,
  fractionalEnhancedOdds: PropTypes.string,
};

Outcome.defaultProps = {
  fractionalEnhancedOdds: null,
  title: null,
};

export default Outcome;
