import PropTypes from 'prop-types';

export const MarketLayout = {
  ONE_COLUMN: '1-column',
  TWO_COLUMN: '2-column',
  THREE_COLUMN: '3-column',
  MULTI_MARKET: 'multi-market',
};

export const marketLayoutType = PropTypes.oneOf(Object.values(MarketLayout));
