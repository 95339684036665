import reducer from './duck';
import App from './components/app';

const { addReducers } = window.reduxState;

addReducers({
  featuredBets: reducer,
});

export default App;
