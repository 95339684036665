import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'underscore';

import Outcomes from '../outcomes';
import { makeGetOutcomesByIdsSortByPrice } from '../../selectors';

const SingleMarket1Column = ({ id, ...props }) => (
  <div className={`market-${id} market-view-body-wrapper`}>
    <Outcomes
      id={id}
      {...props}
      outcomeClassName="bvs-button-sport outcome-button"
    />
  </div>
);

SingleMarket1Column.propTypes = {
  id: PropTypes.number.isRequired,
  numberOfOutcomes: PropTypes.number.isRequired,
};

const makeMapStateToProps = () => {
  const getOutcomesById = makeGetOutcomesByIdsSortByPrice();

  return (state, { o: outcomesIds, numberOfOutcomes }) => ({
    o: getOutcomesById(state, { outcomesIds, numberOfOutcomes }),
  });
};

export default connect(makeMapStateToProps, null, null, {
  areStatePropsEqual: isEqual,
})(SingleMarket1Column);
