import { t } from 'bv-i18n';

const SuspendedMarket = () => (
  <div className="outcomes">
    <button
      type="button"
      className="outcome bvs-button-sport outcome-button disabled"
    >
      {t('suspended')}
    </button>
  </div>
);

export default SuspendedMarket;
