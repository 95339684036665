// 0 equals
// -1 will place outcome 1 before 2
// +1 will place outcome 1 after 2
// We want lower prices before, but SP after all
export default (outcome1, outcome2) => {
  // Equality
  if (outcome1.prd === outcome2.prd) return 0;

  // Checks SP first and sort them
  if (outcome1.prd === 0) return 1;
  if (outcome2.prd === 0) return -1;

  return outcome1.prd > outcome2.prd ? 1 : -1;
};
