import { useContext } from 'react';
import PropTypes from 'prop-types';
import { isMarketSuspended, shouldApplyAmericanFormat } from 'sportsbook-helpers';
import OutcomeContainer from './outcome_container';
import SuspendedMarket from './suspended_market';
import { EventContext } from '../contexts';

const Outcomes = ({
  componentId, st, o: outcomesIds, outcomeClassName, outcomes,
}) => {
  const { useAmericanFormat } = useContext(EventContext);
  if (isMarketSuspended({ st })) return <SuspendedMarket />;

  const displayOutcomeIds = shouldApplyAmericanFormat(useAmericanFormat, outcomes)
    ? outcomesIds.toReversed()
    : outcomesIds;

  return (
    <div className="outcomes">
      {displayOutcomeIds.map((outcomeId) => (
        <OutcomeContainer
          id={outcomeId}
          componentId={componentId}
          outcomeClassName={outcomeClassName}
          key={outcomeId}
        />
      ))}
    </div>
  );
};

Outcomes.propTypes = {
  componentId: PropTypes.number.isRequired,
  st: PropTypes.number,
  outcomeClassName: PropTypes.string,
  o: PropTypes.instanceOf(Array),
  outcomes: PropTypes.instanceOf(Array),
};

Outcomes.defaultProps = {
  st: null,
  outcomeClassName: null,
  o: null,
  outcomes: [],
};

export default Outcomes;
