import PropTypes from 'prop-types';
import MarketContainer from '../market_container';

const SingleMarketLayout = ({ marketIds, ...props }) => (
  <MarketContainer id={marketIds[0]} {...props} />
);

SingleMarketLayout.propTypes = {
  marketIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SingleMarketLayout;
