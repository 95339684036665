import { useMemo } from 'react';
import { v as bvVar } from 'bv';
import PropTypes from 'prop-types';
import { Link, Icon } from 'bv-components';
import { EventIcons } from 'sportsbook-components';
import getMarketLayoutComponent from '../factories/markets_layout';
import { marketLayoutType } from '../helpers/types';
import { EventContext } from '../contexts';

const Event = ({
  id, componentId, eventHref, fullDescription, marketIds, metadata, eventMarketsCount,
  marketDescription, layout, opponentADescription, opponentBDescription, startTime, americanFormat,
}) => {
  const MarketLayout = getMarketLayoutComponent({ layout });
  const eventContextValue = useMemo(() => ({ useAmericanFormat: americanFormat && bvVar('useAmericanFormat') }), [americanFormat]);

  return (
    <EventContext.Provider value={eventContextValue}>
      <div className={`coupon-wrapper event-${id}`}>
        <Link className="coupon-header" to={eventHref}>
          <h3 className="coupon-header-title">{fullDescription}</h3>
          <p className="coupon-header-title">{startTime}</p>
          <div className="coupon-header-subtitle">
            {marketDescription && <div className="coupon-header-subtitle__text">{marketDescription}</div>}
            <div className="coupon-header-icons">
              {metadata && <EventIcons {...metadata} />}
            </div>
            <div className="coupon-header-total">
              {eventMarketsCount}
              <Icon id="arrow-right" main />
            </div>
          </div>
        </Link>
        <MarketLayout
          componentId={componentId}
          marketIds={marketIds}
          opponentADescription={opponentADescription}
          opponentBDescription={opponentBDescription}
        />
      </div>
    </EventContext.Provider>
  );
};

Event.propTypes = {
  id: PropTypes.number.isRequired,
  componentId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  fullDescription: PropTypes.string.isRequired,
  marketDescription: PropTypes.string,
  opponentADescription: PropTypes.string.isRequired,
  opponentBDescription: PropTypes.string.isRequired,
  eventMarketsCount: PropTypes.number.isRequired,
  eventHref: PropTypes.string.isRequired,
  marketIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  metadata: PropTypes.instanceOf(Object).isRequired,
  layout: marketLayoutType.isRequired,
  startTime: PropTypes.string.isRequired,
  americanFormat: PropTypes.bool.isRequired,
};

Event.defaultProps = {
  marketDescription: null,
};

export default Event;
