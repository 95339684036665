import {
  omit, pick, property, propertyOf,
} from 'underscore';

import { addOutcomes, removeOutcomes } from 'sportsbook-outcomes-duck';
import { addMarkets, removeMarkets } from 'sportsbook-markets-duck';
import { makeGetComponentById } from './selectors';
import fetchData from './api';

export const FEATURED_FETCH_DATA_INIT = 'featuredBets/FETCH_DATA_INIT';
export const FEATURED_FETCH_DATA_SUCCESS = 'featuredBets/FETCH_DATA_SUCCESS';
export const FEATURED_RESET_DATA_SUCCESS = 'featuredBets/RESET_DATA_SUCCESS';

export const featuredFetchDataInit = (componentId) => ({
  type: FEATURED_FETCH_DATA_INIT,
  componentId,
});

export const featuredFetchDataSuccess = (componentId) => (featured) => ({
  type: FEATURED_FETCH_DATA_SUCCESS,
  componentId,
  featured,
});

const extractFields = (array, fields) => array.reduce((acc, obj) => ({
  ...acc,
  [obj.id]: pick(obj, fields),
}), {});

export const featuredFetchData = (componentId) => (dispatch) => {
  dispatch(featuredFetchDataInit(componentId));
  return fetchData(componentId)
    .then(({
      events, action, markets, outcomes, sport,
    }) => {
      dispatch(addOutcomes(outcomes));
      dispatch(addMarkets(markets));
      dispatch(featuredFetchDataSuccess(componentId)({
        sport: { ...sport, action },
        events,
        // some markets/outcomes fields are specific to the component, so we cannot get them from
        // the global markets/outcomes (we can have issues if the same market is loaded elsewhere)
        markets: extractFields(markets, ['des', 'o']),
        outcomes: extractFields(outcomes, ['title']),
      }));
    });
};

const resetFeaturedDataSuccess = (componentId) => ({
  type: FEATURED_RESET_DATA_SUCCESS,
  componentId,
});

export const resetFeaturedData = (componentId) => (dispatch, getState) => {
  const state = getState();
  const getComponentById = makeGetComponentById();
  const { events } = getComponentById(state, componentId);

  // Once we get the events, the method is the same as in upcoming
  // Same data structure, perhaps we should have an action
  // to remove both MarketsAndOutcomes in 1 call
  const marketIds = events.flatMap(property('marketIds'));
  const outcomeIds = marketIds.map(propertyOf(state.markets)).filter(Boolean).flatMap(property('o'));

  dispatch(removeOutcomes(outcomeIds));
  dispatch(removeMarkets(marketIds));
  dispatch(resetFeaturedDataSuccess(componentId));
};

const initialState = {
  componentsById: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FEATURED_FETCH_DATA_INIT:
      return {
        componentsById: {
          ...state.componentsById,
          [action.componentId]: {
            sport: {},
            events: [],
            markets: {},
            outcomes: {},
            loaded: false,
          },
        },
      };
    case FEATURED_FETCH_DATA_SUCCESS:
      return {
        componentsById: {
          ...state.componentsById,
          [action.componentId]: {
            ...action.featured,
            loaded: true,
          },
        },
      };
    case FEATURED_RESET_DATA_SUCCESS:
      return {
        componentsById: {
          ...omit(state.componentsById, action.componentId),
        },
      };
    default:
      return state;
  }
};
