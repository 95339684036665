import PropTypes from 'prop-types';
import Outcomes from '../outcomes';

const SingleMarket3Column = ({ id, ...props }) => (
  <div className={`market-${id} market-view-body-wrapper outcome-3cols`}>
    <Outcomes
      id={id}
      {...props}
      outcomeClassName="bvs-button-sport outcome-button-1x2"
    />
  </div>
);

SingleMarket3Column.propTypes = {
  id: PropTypes.number.isRequired,
};

export default SingleMarket3Column;
