import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { formatPrice } from 'sportsbook-helpers';
import { usePriceFormatId } from 'bv-hooks';
import withOutcome from 'sportsbook-with-outcome';
import Outcome from './outcome';
import { makeGetComponentById } from '../selectors';

const OutcomeContainer = ({
  componentId,
  outcome,
  toggleBetslip,
  selected,
  enhancedOutcome,
  outcomeClassName,
}) => {
  const priceFormatId = usePriceFormatId();
  const getComponentById = makeGetComponentById();
  const component = useSelector((state) => getComponentById(state, componentId));
  const featuredOutcome = component.outcomes[outcome.id];
  const fractionalEnhancedOdds = (
    enhancedOutcome && formatPrice(enhancedOutcome.fractionalEnhancedOdds, priceFormatId)
  ) || null;

  return (
    <button
      type="button"
      className={classnames(
        `outcome outcome-${outcome.id}`, { active: selected }, outcomeClassName,
      )}
      onClick={() => { toggleBetslip({ clickSource: 'featured-widget' }); }}
    >
      <Outcome
        {...outcome}
        {...featuredOutcome}
        fractionalEnhancedOdds={fractionalEnhancedOdds}
      />
    </button>
  );
};

OutcomeContainer.propTypes = {
  componentId: PropTypes.number.isRequired,
  outcomeClassName: PropTypes.string.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
  enhancedOutcome: PropTypes.instanceOf(Object),
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
};

OutcomeContainer.defaultProps = {
  enhancedOutcome: null,
};

export default withOutcome(OutcomeContainer);
