import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { isEmpty } from 'underscore';
import { Skeleton } from 'bv-components';

import { featuredFetchData, resetFeaturedData } from '../duck';
import { makeGetComponentById } from '../selectors';
import SportContainer from './sport_container';

const FeaturedApp = ({ componentId }) => {
  const dispatch = useDispatch();
  const getComponentById = makeGetComponentById();
  const component = useSelector(
    (state) => getComponentById(state, componentId),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(featuredFetchData(componentId));

    return () => dispatch(resetFeaturedData(componentId));
  }, [componentId]);

  if (!component) return null;

  const { sport, loaded } = component;

  if (!loaded) return <Skeleton skeletonType="featured-component" />;
  if (isEmpty(sport)) return null;

  return (
    <section className="featured-component">
      <SportContainer
        id={sport.id}
        componentId={componentId}
        description={sport.description}
        eventPathId={sport.sportEventPathId}
        eventIds={sport.eventIds}
        filterTodayEvents={sport.filterTodayEvents}
        href={sport.action}
        numberOfEventsPerCard={sport.numberOfEventsPerCard}
        layout={sport.layout}
      />
    </section>
  );
};

FeaturedApp.propTypes = {
  componentId: PropTypes.number.isRequired,
};

export default FeaturedApp;
