import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Event from './event';
import { makeGetComponentById } from '../selectors';

const EventContainer = ({
  id,
  meetingId,
  getEventHref,
  ...props
}) => (
  <Event
    id={id}
    meetingId={meetingId}
    eventHref={getEventHref({ meetingId, eventId: id })}
    {...props}
  />
);

EventContainer.propTypes = {
  id: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  getEventHref: PropTypes.func.isRequired,
};

const makeMapStateToProps = () => {
  const getComponentById = makeGetComponentById();

  return (state, { componentId, layout, marketIds }) => ({
    marketDescription: layout !== 'multi-market'
      && getComponentById(state, componentId).markets[marketIds[0]]?.des,
  });
};

export default connect(makeMapStateToProps)(EventContainer);
